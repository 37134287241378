import { Asset } from '@/organisms/AngelPlayer'

export type TrackVideoEventName = TrackVideoContentEventName | TrackVideoPlaybackEventName | PifAttributionEventName

export enum TrackVideoContentEventName {
  Started = 'Video Content Started',
  Viewed = 'Video Content Viewed',
  Credits = 'Video Credits Started',
  Completed = 'Video Content Completed',
}

export enum TrackVideoPlaybackEventName {
  Started = 'Video Playback Started',
  Resumed = 'Video Playback Resumed',
  Paused = 'Video Playback Paused',
  Completed = 'Video Playback Completed',
  SeekStarted = 'Video Playback Seek Started',
  SeekCompleted = 'Video Playback Seek Completed',
  // Exited = 'Video Playback Exited', TODO
  // Interrupted = 'Video Playback Interrupted', TODO
  // BufferStarted = 'Video Playback Buffer Started', TODO
  // BufferCompleted = 'Video Playback Buffer Completed', TODO
}

export enum PifAttributionEventName {
  PifAttributionViewed = 'PIF Attribution Viewed',
  PifThankYouStarted = 'PIF Thank You Started',
  PifThankYouCompleted = 'PIF Thank You Completed',
  PifThankYouCancelled = 'PIF Thank You Cancelled',
  PifPayItForwardClicked = 'Clicked Pay It Forward From Episode View',
}

/* This matches the Segment Angel Tracking Plan data structure
   except for the comments that note discrepancies */
export interface VideoContentTrackEvent {
  airdate?: string // required, backend doesn't always return it
  asset_id: string
  bitrate?: number | null // required, not sure where to get it yet
  description: string
  episode?: string | null
  position?: number | null // required, backend doesn't always return it
  program?: string | null // required, backend doesn't always return it, usually the project slug
  publisher?: string | null
  season?: string // required, backend doesn't always return it
  title: string
  total_length?: number
  video_type: 'episode' | 'video' | 'story' | 'livestream' | 'pif-explainer' | 'torch'
}

export interface VideoPlaybackTrackEvent {
  ad_asset_ids?: string[]
  ad_enabled?: boolean
  ad_pod_ids?: string[]
  ad_type?: string
  bitrate?: number
  content_asset_ids?: string[]
  content_pod_ids?: string[]
  framerate?: number
  full_screen?: boolean
  livestream?: boolean
  position: number
  method?: string // only used for 'Video Playback Interrupted'
  quality?: string
  seek_position?: number // only used for  `Video Playback Seek Started', we could possibly update the Angel Bitmovin player to supply this
  session_id?: string
  sound?: number
  total_length?: number
  video_player?: string
  video_type: 'episode' | 'video' | 'story' | 'livestream' | 'pif-explainer' | 'torch'
}

/*
    The purpose of overrides is to allow for an event producer to inject a value that is easier to
    grab at the time the event is produced. Quite a bit of the data is transformed early on from an
    API request to get episode data.
 */
export interface EventValueOverrides {
  position?: number
}

function createVideoContentTrackEvent(asset: Asset, overrides?: EventValueOverrides): VideoContentTrackEvent {
  return {
    airdate: asset.analytics.airdate,
    asset_id: asset.analytics.content_id,
    description: asset.analytics.description,
    episode: asset.analytics.episodeNumber?.toString(),
    position: Math.floor(overrides?.position || asset.analytics?.position),
    program: asset.projectSlug,
    publisher: asset.publisherName,
    season: asset.analytics.seasonId,
    title: asset.analytics.title,
    total_length: asset.analytics.total_length,
    video_type: asset.analytics.videoType,
  }
}

function createVideoPlaybackTrackEvent(asset: Asset, overrides?: EventValueOverrides): VideoPlaybackTrackEvent {
  return {
    position: Math.floor(overrides?.position || asset.analytics?.position),
    video_type: asset.analytics.videoType,
  }
}

export function createVideoTrackEvent(
  eventName: TrackVideoEventName,
  asset: Asset,
  overrides?: EventValueOverrides,
): VideoContentTrackEvent | VideoPlaybackTrackEvent | null {
  switch (eventName) {
    case TrackVideoContentEventName.Viewed:
    case TrackVideoContentEventName.Started:
    case TrackVideoContentEventName.Completed:
    case TrackVideoContentEventName.Credits:
      return createVideoContentTrackEvent(asset, overrides)
    case TrackVideoPlaybackEventName.Started:
    case TrackVideoPlaybackEventName.Resumed:
    case TrackVideoPlaybackEventName.Paused:
    case TrackVideoPlaybackEventName.Completed:
    case TrackVideoPlaybackEventName.SeekStarted:
    case TrackVideoPlaybackEventName.SeekCompleted:
      return createVideoPlaybackTrackEvent(asset, overrides)
    default:
      return null
  }
}
