import React, { ReactNode } from 'react'
import classNames from 'classnames'
import { useUser } from '@/services/UserService'
import { useStrictModeDetector } from '@/services/WatchableService/hooks'
import { useSafeAnalytics } from '@/utils/analytics'
import { AngelPlayerContextProvider } from './AngelPlayerContext'
import { BitmovinPlayer } from './BitmovinPlayer'
import { Asset, PlayerOverlay, SmpteController } from './types'

export interface AngelPlayerProps {
  autoplay?: boolean
  asset: Asset | null | undefined
  overlays?: PlayerOverlay[]
  smpteTimestamp?: string
  className?: string
  aspectClassName?: string
  children?: ReactNode | undefined
  playerContainerClassName?: string
  onStart?: () => void
  onControllerInit?: (controller: SmpteController) => void
  shouldSaveWatchPosition?: boolean
}

export const AngelPlayer: React.FC<AngelPlayerProps> = ({
  autoplay,
  asset,
  overlays,
  smpteTimestamp,
  children,
  className,
  aspectClassName,
  playerContainerClassName,
  onStart,
  shouldSaveWatchPosition = true,
  onControllerInit,
  ...rest
}) => {
  const { userId } = useUser()
  const analytics = useSafeAnalytics()
  const isStrict = useStrictModeDetector()

  return asset ? (
    <AngelPlayerContextProvider
      asset={asset}
      smpteTimestamp={smpteTimestamp}
      onStart={onStart}
      shouldSaveWatchPosition={shouldSaveWatchPosition}
      onControllerInit={onControllerInit}
    >
      <div data-testid="angel-player" className={classNames('angel-player', className)}>
        <div className={playerContainerClassName}>
          <BitmovinPlayer
            autoplay={autoplay}
            asset={asset}
            overlays={overlays}
            aspectClassName={aspectClassName}
            anonymousUserId={analytics?.user()?.anonymousId}
            loggedInUserId={userId}
            enableAds
            isStrict={isStrict}
            {...rest}
          />
          {children}
        </div>
      </div>
    </AngelPlayerContextProvider>
  ) : null
}
