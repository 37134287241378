import { Asset, SmpteTimestamp, UpNextConfig, VideoType } from '@/organisms/AngelPlayer'
import { getFrameNumberFromSMPTE } from '@/organisms/NftPlayer/useFrameData/FrameDataHelper'
import { CMSWatchable } from '@/services/CmsService/types'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'

const FRAME_INFO = {
  frameRate: 24,
  framesDroppedEachMinute: 0,
  adjustmentFactor: 1,
}

interface AssetProps {
  guid: string
  slug: string
  name: string
  url: string
  title: string
  videoType: VideoType
  subtitle: string
  poster?: string
  publisherName?: string
  projectName?: string
  projectSlug?: string
  description?: string
  position?: number | SmpteTimestamp
  upNext?: UpNextConfig
  releaseDate?: string
  seasonId?: string
  episodeNumber?: number
  duration?: number
  credits?: number
  vmapUrl?: string
}

export function createAsset({
  guid,
  publisherName,
  projectSlug,
  projectName,
  name,
  url,
  poster,
  title,
  subtitle,
  description,
  position = 0,
  upNext,
  slug,
  seasonId,
  episodeNumber,
  releaseDate,
  duration,
  videoType,
  credits,
  vmapUrl,
}: AssetProps): Asset {
  return {
    name,
    publisherName,
    projectName,
    projectSlug,
    position,
    vmapUrl,
    ...FRAME_INFO,
    sourceConfig: {
      slug,
      guid,
      hls: url,
      poster,
      title,
      subtitle,
      description,
      credits,
      metadata: {
        upNext,
        videoType,
      },
    },
    analytics: {
      airdate: releaseDate,
      content_id: guid,
      description: description || subtitle,
      episodeNumber,
      position: typeof position === 'string' ? getFrameNumberFromSMPTE(position) : Math.floor(position),
      seasonId,
      title,
      total_length: duration,
      videoType,
    },
  }
}

export function createAssetFromCMSWatchable(watchable: CMSWatchable | undefined) {
  if (!watchable) return null

  return createAsset({
    ...watchable,
    poster: watchable?.thumbnail
      ? getCloudinaryImageUrl({
          path: `/v${watchable?.thumbnail?.[0]?.version}/${watchable?.thumbnail?.[0]?.public_id}`,
          height: watchable?.thumbnail?.[0]?.height,
          width: watchable?.thumbnail?.[0]?.width,
          format: 'webp',
        })
      : undefined,
  })
}
