import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Maybe } from '@graphql-tools/utils'
import type {
  AudioChangedEvent,
  AudioTrack,
  SubtitleEvent,
  SubtitleTrack,
  UserInteractionEvent,
  TimeChangedEvent,
  PlayerEventBase,
} from 'bitmovin-player'
import { UIInstanceManager } from 'bitmovin-player-ui'
import {
  findSavedAudioTrack,
  findSavedSubtitleTrack,
  getVideoSettings,
  saveVideoSettings,
} from '@/organisms/AngelPlayer/AngelPlayerContext/settings'
import { useUser } from '@/services/UserService'
import { upsertWatchPosition } from '@/services/WatchableService'
import { useSafeTrack } from '@/utils/analytics'
import { AngelPlayerData, Asset, CommunityViewChagedArgs, SmpteController, TimelineMarker, UIManager } from '../types'
import {
  createVideoTrackEvent,
  TrackVideoContentEventName,
  TrackVideoEventName,
  TrackVideoPlaybackEventName,
} from './trackEventFactory'

interface AngelPlayerAPIProps {
  asset?: Asset
  controller?: SmpteController
  getCurrentTime?: () => number
  isSourceLoaded?: boolean
  onAudioChanged: (event: AudioChangedEvent) => void
  onPaused: (event: UserInteractionEvent, angelPlayerEvent: AngelPlayerEvent) => void
  onPlaybackFinished: () => void
  onPlaying: () => void
  onReady: () => void
  onSeek: () => void
  onSeeked: (event: UserInteractionEvent, angelPlayerEvent: AngelPlayerEvent) => void
  onSkip: () => void
  onSourceLoaded: (event: PlayerEventBase) => void
  onSubtitleDisabled: (event: SubtitleEvent) => void
  onSubtitleEnabled: (event: SubtitleEvent) => void
  onTimeChanged: (event: TimeChangedEvent) => void
  saveWatchPosition: () => void
  setController?: (smpteController: SmpteController) => void
  setIsSourceLoaded?: (isSourceLoaded: boolean) => void
  setUIManager?: (uiManager: UIManager) => void
  uiManager?: UIManager
  addTimelineMarker?: (timelineMarker: TimelineMarker) => void
  removeTimelineMarker?: (timelineMarker: TimelineMarker) => void
  markScene?: (timelineMarker: TimelineMarker) => void
}

export const AngelPlayerContext = createContext({})
export const AngelPlayerAPIContext = createContext<AngelPlayerAPIProps>({} as AngelPlayerAPIProps)

function getSmpteObjectFromString(smpteTimestamp: string) {
  if (!smpteTimestamp) return null

  return {
    full: smpteTimestamp,
    short: smpteTimestamp.substring(0, smpteTimestamp.length - 3),
    frame: smpteTimestamp.substring(smpteTimestamp.length - 2, smpteTimestamp.length),
  }
}

export type AngelPlayerEvent = {
  hasPlayed: boolean
  time: Maybe<number>
  uiManager?: UIManager
}

interface Props {
  children: JSX.Element
  asset: Asset
  episodeGuid?: string
  smpteTimestamp?: string
  startTime?: number
  onStart?: (asset: Asset) => void
  onPause?: (event: UserInteractionEvent, angelPlayerEvent: AngelPlayerEvent) => void
  onSeeked?: (event: UserInteractionEvent, angelPlayerEvent: AngelPlayerEvent) => void
  onPlay?: (angelPlayerEvent: AngelPlayerEvent) => void
  onControllerInit?: (controller: SmpteController) => void
  shouldSaveWatchPosition?: boolean
  introStartTime?: number
  introEndTime?: number
  defaultCommunityView?: 'on' | 'off'
}

export function AngelPlayerContextProvider({
  children,
  asset,
  episodeGuid,
  smpteTimestamp = '00:00:00:00',
  onStart,
  onPause,
  onPlay,
  onSeeked,
  shouldSaveWatchPosition = true,
  startTime,
  onControllerInit,
  introStartTime,
  introEndTime,
  defaultCommunityView,
}: Props) {
  const track = useSafeTrack()
  const { user } = useUser()
  const client = useApolloClient()
  const [controller, setController] = useState<SmpteController>()
  const [uiManager, setUIManager] = useState<UIManager>()
  const [currentSMPTE, setCurrentSMPTE] = useState(getSmpteObjectFromString(smpteTimestamp))
  const [seeking, setSeeking] = useState(false)
  const [playing, setPlaying] = useState(false)
  const [inIntro, setIntro] = useState(false)
  const [hasPlayed, setHasPlayed] = useState(false)
  const [hasInteracted, setHasInteracted] = useState(false)
  const [isCreditsTracked, setIsCreditsTracked] = useState(false)
  const [playingProgress, setPlayingProgress] = useState<number>(startTime ?? 0)
  const [isSourceLoaded, setIsSourceLoaded] = useState(false)
  const [showCommunityView, setShowCommunityView] = useState(false)

  const sendTrackEvent = useCallback(
    (eventName: TrackVideoEventName) => {
      const payload = createVideoTrackEvent(eventName, asset, { position: controller?.getCurrentTime() })
      track(eventName, payload)
    },
    [track, asset, controller],
  )

  const checkIntro = useCallback((playingProgress: number, introStartTime: number, introEndTime: number) => {
    const isInIntro = playingProgress >= introStartTime && playingProgress <= introEndTime - 5
    setIntro(isInIntro)
  }, [])

  useEffect(() => {
    if (defaultCommunityView === 'on') {
      uiManager?.currentUi.onCommunityViewToggled?.dispatch(uiManager.currentUi, {
        isOn: false,
        caller: 'app',
      })
      setShowCommunityView(true)
    }
  }, [defaultCommunityView, uiManager])

  useEffect(() => {
    const handleCommunityViewToggled = (args: CommunityViewChagedArgs) => {
      setShowCommunityView(args.isOn)
      track(`Toggled Side Panel View ${args.isOn ? 'On' : 'Off'}`)
    }

    uiManager?.uiInstanceManagers.forEach((instanceManager) => {
      instanceManager?.onCommunityViewToggled?.subscribe(
        (_uiInstanceManager: UIInstanceManager, args: CommunityViewChagedArgs) => {
          handleCommunityViewToggled(args)
        },
      )
    })

    return () => {
      uiManager?.uiInstanceManagers.forEach((instanceManager) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        instanceManager?.onCommunityViewToggled?.unsubscribe(
          (_uiInstanceManager: UIInstanceManager, args: CommunityViewChagedArgs) => {
            handleCommunityViewToggled(args)
          },
        )
      })
    }
  }, [track, uiManager])

  const updateCurrentSMPTE = useCallback(() => {
    if (!controller || !controller.getCurrentSmpte()) return
    const smpte = getSmpteObjectFromString(controller.getCurrentSmpte() as string)
    setCurrentSMPTE(smpte)

    return smpte
  }, [controller, setCurrentSMPTE])

  const saveWatchPosition = useCallback(() => {
    if (shouldSaveWatchPosition && user && controller) {
      upsertWatchPosition({ position: controller.getCurrentTime(), guid: asset.sourceConfig.guid }, client)
    }
  }, [client, asset, controller, user, shouldSaveWatchPosition])

  const toggleCommunityViewOn = useCallback(() => {
    uiManager?.currentUi?.onCommunityViewToggled?.dispatch(uiManager.currentUi, {
      isOn: true,
      caller: 'app',
    })
    setShowCommunityView(true)
  }, [uiManager])

  const toggleCommunityViewOff = useCallback(() => {
    uiManager?.currentUi?.onCommunityViewToggled?.dispatch(uiManager.currentUi, {
      isOn: false,
      caller: 'app',
    })
    setShowCommunityView(false)
  }, [uiManager])

  const onPaused = useMemo(
    () => (event: UserInteractionEvent) => {
      updateCurrentSMPTE()
      setPlaying(false)
      setSeeking(event.issuer === 'ui-seek')
      saveWatchPosition()
      sendTrackEvent(TrackVideoPlaybackEventName.Paused)
      const time = controller?.getCurrentTime()
      onPause?.(event, { hasPlayed, time, uiManager })
    },
    [updateCurrentSMPTE, saveWatchPosition, sendTrackEvent, controller, onPause, hasPlayed, uiManager],
  )

  const onPlaying = useCallback(() => {
    if (!hasPlayed) {
      sendTrackEvent(TrackVideoContentEventName.Started)
      sendTrackEvent(TrackVideoPlaybackEventName.Started)
      onStart?.(asset)
    } else {
      sendTrackEvent(TrackVideoPlaybackEventName.Resumed)
    }

    const time = controller?.getCurrentTime()
    onPlay?.({ hasPlayed, time, uiManager })
    setPlaying(true)
    setHasPlayed(true)
    setHasInteracted(true)
    setSeeking(false)
  }, [hasPlayed, controller, onPlay, uiManager, sendTrackEvent, onStart, asset])

  const onSeek = useCallback(() => {
    setSeeking(true)
    sendTrackEvent(TrackVideoPlaybackEventName.SeekStarted)
  }, [setSeeking, sendTrackEvent])

  const onSourceLoaded = useCallback(() => {
    setIsSourceLoaded(true)
  }, [])

  const handleOnSeeked = useCallback(
    (event: UserInteractionEvent) => {
      const time = controller?.getCurrentTime() as number
      setPlayingProgress(time)
      introStartTime && introEndTime && checkIntro(time, introStartTime, introEndTime)
      updateCurrentSMPTE()
      setSeeking(false)
      setHasInteracted(true)
      sendTrackEvent(TrackVideoPlaybackEventName.SeekCompleted)
      onSeeked?.(event, { time, hasPlayed, uiManager })
    },
    [
      controller,
      updateCurrentSMPTE,
      sendTrackEvent,
      onSeeked,
      hasPlayed,
      uiManager,
      introStartTime,
      introEndTime,
      checkIntro,
    ],
  )

  const onSkip = useCallback(() => {
    if (introEndTime) {
      setPlayingProgress(introEndTime)
      controller?.seek(introEndTime)
      setIntro(false)
    }
  }, [introEndTime, setPlayingProgress, controller])

  const onPlaybackFinished = useCallback(() => {
    sendTrackEvent(TrackVideoContentEventName.Completed)
    sendTrackEvent(TrackVideoPlaybackEventName.Completed)
  }, [sendTrackEvent])

  const onTimeChanged = useCallback(() => {
    const currentTime = controller?.getCurrentTime() ?? 0
    //TODO check PlayingProgress and see if we can remove setPlayingProgress here
    setPlayingProgress(currentTime)
    if (introStartTime && introEndTime) {
      checkIntro(currentTime, introStartTime, introEndTime)
    }
    const credits = asset.sourceConfig.credits ?? 0
    if (!isCreditsTracked && credits && currentTime > credits) {
      setIsCreditsTracked(true)
      sendTrackEvent(TrackVideoContentEventName.Credits)
    }
  }, [
    checkIntro,
    controller,
    setPlayingProgress,
    isCreditsTracked,
    setIsCreditsTracked,
    sendTrackEvent,
    asset,
    introStartTime,
    introEndTime,
  ])

  const onReady = useCallback(() => {
    if (!controller) return

    const audioTrack: AudioTrack = findSavedAudioTrack(controller.getAvailableAudio())
    const subtitleTrack: SubtitleTrack = findSavedSubtitleTrack(controller.getSubtitleList())
    controller.setAudio(audioTrack.id)
    controller.enableSubTitle(subtitleTrack.id)
  }, [controller])

  const onAudioChanged = useCallback((event: AudioChangedEvent) => {
    const settingsCookie = getVideoSettings()
    const audioTrackId = event.targetAudio.lang
    saveVideoSettings({ ...settingsCookie, audioTrack: audioTrackId })
  }, [])

  const onSubtitleEnabled = useCallback((event: SubtitleEvent) => {
    const settingsCookie = getVideoSettings()
    const subtitleTrackId = event.subtitle.lang
    saveVideoSettings({ ...settingsCookie, subtitleTrack: subtitleTrackId })
  }, [])

  const onSubtitleDisabled = useCallback(() => {
    const settingsCookie = getVideoSettings()
    saveVideoSettings({ ...settingsCookie, subtitleTrack: undefined })
  }, [])

  const handleSetController = useCallback(
    (smpteController: SmpteController) => {
      setController(smpteController)
      onControllerInit?.(smpteController)
    },
    [onControllerInit],
  )

  const apiValue = useMemo(
    () => ({
      asset,
      controller,
      isSourceLoaded,
      onAudioChanged,
      onPaused,
      onPlaybackFinished,
      onPlaying,
      onReady,
      onSeek,
      onSeeked: handleOnSeeked,
      onSkip,
      onSourceLoaded,
      onSubtitleDisabled,
      onSubtitleEnabled,
      onTimeChanged,
      saveWatchPosition,
      setController: handleSetController,
      setIsSourceLoaded,
      setUIManager,
      uiManager,
      addTimelineMarker: uiManager?.addTimelineMarker,
      removeTimelineMarker: uiManager?.removeTimelineMarker,
      showCommunityView,
      toggleCommunityViewOn,
      toggleCommunityViewOff,
    }),
    [
      asset,
      controller,
      isSourceLoaded,
      onAudioChanged,
      onPaused,
      onPlaybackFinished,
      onPlaying,
      onReady,
      onSeek,
      handleOnSeeked,
      onSkip,
      onSourceLoaded,
      onSubtitleDisabled,
      onSubtitleEnabled,
      onTimeChanged,
      saveWatchPosition,
      handleSetController,
      uiManager,
      showCommunityView,
      toggleCommunityViewOn,
      toggleCommunityViewOff,
    ],
  )

  const value = useMemo(
    () => ({
      asset,
      controller,
      currentSMPTE,
      episodeGuid,
      hasInteracted,
      hasPlayed,
      inIntro,
      isSourceLoaded,
      onAudioChanged,
      onPaused,
      onPlaybackFinished,
      onPlaying,
      onReady,
      onSeek,
      onSeeked: handleOnSeeked,
      onSkip,
      onSubtitleDisabled,
      onSubtitleEnabled,
      onTimeChanged,
      playing,
      playingProgress,
      ready: !!controller,
      saveWatchPosition,
      seeking,
      setController,
      setPlayingProgress,
      uiManager,
      showCommunityView,
      toggleCommunityViewOff,
      toggleCommunityViewOn,
    }),
    [
      asset,
      controller,
      currentSMPTE,
      episodeGuid,
      hasInteracted,
      hasPlayed,
      inIntro,
      isSourceLoaded,
      onAudioChanged,
      onPaused,
      onPlaybackFinished,
      onPlaying,
      onReady,
      onSeek,
      handleOnSeeked,
      onSkip,
      onSubtitleDisabled,
      onSubtitleEnabled,
      onTimeChanged,
      playing,
      playingProgress,
      saveWatchPosition,
      seeking,
      uiManager,
      showCommunityView,
      toggleCommunityViewOff,
      toggleCommunityViewOn,
    ],
  )

  return (
    <AngelPlayerAPIContext.Provider value={apiValue}>
      <AngelPlayerContext.Provider value={value}>{children}</AngelPlayerContext.Provider>
    </AngelPlayerAPIContext.Provider>
  )
}

export function useAngelPlayerContext(): AngelPlayerData {
  const angelPlayerState = useContext(AngelPlayerContext)
  if (typeof angelPlayerState === 'undefined') {
    throw new Error('useAngelPlayerContext must be used within a AngelPlayerContextProvider!')
  }
  return angelPlayerState as AngelPlayerData
}

export function useAngelPlayerAPI(): AngelPlayerAPIProps {
  return useContext(AngelPlayerAPIContext)
}

export function withAngelPlayerAPI<T>(WrappedComponent: React.ComponentClass<T>) {
  const ComponentWithContext = (props: T) => {
    return (
      <AngelPlayerAPIContext.Consumer>
        {(value) => {
          return (
            <WrappedComponent
              asset={value.asset}
              controller={value.controller}
              onAudioChanged={value.onAudioChanged}
              onPaused={value.onPaused}
              onPlaybackFinished={value.onPlaybackFinished}
              onPlaying={value.onPlaying}
              onReady={value.onReady}
              onSeek={value.onSeek}
              onSeeked={value.onSeeked}
              onSkip={value.onSkip}
              onSourceLoaded={value.onSourceLoaded}
              onSubtitleDisabled={value.onSubtitleDisabled}
              onSubtitleEnabled={value.onSubtitleEnabled}
              onTimeChanged={value.onTimeChanged}
              saveWatchPosition={value.saveWatchPosition}
              setController={value.setController}
              setUIManager={value.setUIManager}
              uiManager={value.uiManager}
              isSourceLoaded={value.isSourceLoaded}
              setIsSourceLoaded={value.setIsSourceLoaded}
              {...props}
            />
          )
        }}
      </AngelPlayerAPIContext.Consumer>
    )
  }

  ComponentWithContext.displayName = `withAngelPlayerContext(${WrappedComponent.displayName})`

  return ComponentWithContext
}
