import type { AudioTrack, SubtitleTrack } from 'bitmovin-player'
import { getCookie, setCookie } from 'cookies-next'
import { Durations } from '@/constants/durations'

const VIDEO_SETTINGS_COOKIE_NAME = 'angel_video_settings'

export interface VideoPlayerSettings {
  audioTrack?: string
  subtitleTrack?: string
}

export function getVideoSettings(): VideoPlayerSettings {
  return JSON.parse((getCookie(VIDEO_SETTINGS_COOKIE_NAME) as string) || '{}')
}

export function saveVideoSettings(videoSettings: VideoPlayerSettings) {
  setCookie(VIDEO_SETTINGS_COOKIE_NAME, JSON.stringify(videoSettings), {
    maxAge: Durations.TEN_YEARS_IN_SECONDS,
  })
}

export function findSavedAudioTrack(audioTracks: AudioTrack[] = []): AudioTrack {
  const savedAudio = getVideoSettings().audioTrack

  const audioTrack = audioTracks.find((audio) => {
    return audio.lang === savedAudio
  })
  return audioTrack ?? ({} as AudioTrack)
}
export function findSavedSubtitleTrack(subtitleTracks: SubtitleTrack[] = []): SubtitleTrack {
  const savedSubtitle = getVideoSettings().subtitleTrack

  const subtitleTrack = subtitleTracks.find((subtitle) => {
    return subtitle.lang === savedSubtitle
  })
  return subtitleTrack ?? ({} as SubtitleTrack)
}
