import { ApolloClient, gql } from '@apollo/client'
import { getWebClient } from '@/services/ApolloClient'
import { GET_WATCH_POSITION_FOR_GUID } from '@/services/ProjectsService/queries'
import { GetWatchPositionForGuidQuery } from '@/types/codegen-federation'
import { isDefined } from '@/utils/types'

export interface UpsertWatchPositionRequest {
  position: number
  guid: string
}

export const upsertWatchPositionMutation = gql`
  mutation upsertWatchPosition($input: WatchPositionInput!) {
    upsertWatchPosition(input: $input) {
      position
      id
    }
  }
`

export const upsertWatchPosition = async (
  { position, guid }: UpsertWatchPositionRequest,
  client: ApolloClient<object>,
): Promise<void> => {
  if (position >= 60) {
    await client.mutate({
      mutation: upsertWatchPositionMutation,
      variables: {
        input: {
          position: Math.round(position),
          watchableGuid: guid,
        },
      },
      errorPolicy: 'all',
    })
  }
}

export async function getWatchPositionByGuids(guids: string[]) {
  const client = getWebClient()

  const results = await Promise.all(
    guids.map((globalId) => {
      return client.query<GetWatchPositionForGuidQuery>({
        query: GET_WATCH_POSITION_FOR_GUID,
        variables: { globalId },
        errorPolicy: 'all',
      })
    }),
  )

  return results
    .map((item) => item.data.episodeByGlobalId)
    .map((watchPosition) => {
      if (!watchPosition) return null
      const position = watchPosition?.watchPosition?.position ?? 0
      const duration = watchPosition?.source?.duration ?? 0

      return {
        id: watchPosition.id as string,
        guid: watchPosition?.watchPosition?.watchableGuid as string,
        position: (position / duration) * 100,
      }
    })
    .filter(isDefined)
}
